import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import * as actionTypes from '../actions/actionTypes'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  scaleFields: {
    marginLeft: theme.spacing(8),
  },
})) 

export default function AssessmentTemplatesEditor(props) {
  const classes = useStyles()
  const assessment_templates = useSelector(state => state.iep.data.assessment_templates)
  let [templates, setTemplates] = useState(_.cloneDeep(assessment_templates) || [])
  const dispatch = useDispatch()

  function handleChangeName(idx, value) {
    let newTemplates = _.cloneDeep(templates)
    newTemplates[idx].name = value
    setTemplates(newTemplates)
  }

  function handleChangeScale(idx, scale_idx, value) {
    let newTemplates = _.cloneDeep(templates)
    newTemplates[idx].scales[scale_idx] = value
    setTemplates(newTemplates)
  }

  useEffect( () => {
    dispatch({
      type: actionTypes.IEP_USER_DATA,
      payload: {
        request: {
          url: "/author/user/data",
        }
      }    
    })
  }, [dispatch])

  useEffect( () => {
    setTemplates(_.cloneDeep(assessment_templates || []))
  }, [assessment_templates])

  // function handleKeyUp(e) {
  //   if (e.key === 'Enter') {
  //     addItem()
  //   } else if ((e.key === 'Backspace') && (e.target.value === "")) {
  //     setServices(_.compact(services))
  //   }
  // }

  function addTemplate() {
    setTemplates([...(templates || []), {name: "", scales: []}])
  }

  function addScale(idx) {
    let newTemplates = _.cloneDeep(templates)
    newTemplates[idx].scales.push("")
    setTemplates(newTemplates)
  }

  function saveChanges() {
    dispatch({
      type: actionTypes.USER_UPDATE,
      payload: {
        request: {
          url: "/user",
          method: 'patch',
          data: { assessment_templates: templates }
        }
      }    
    }).then(() => {
      props.onSave()
    }).catch(() => {
      props.onSave()
    })
  }

  function handleScaleKeyUp(template_idx, scale_idx, e) {
    if (e.key === 'Enter') {
      addScale(template_idx)
    } else if ((e.key === 'Backspace') && (e.target.value === "")) {
      let newTemplates = _.cloneDeep(templates)
      newTemplates[template_idx].scales = _.compact(newTemplates[template_idx].scales)
      setTemplates(newTemplates)
    }
  }

  function handleNameKeyUp(template_idx, e) {
    if ((e.key === 'Backspace') && (e.target.value === "") && _.isEmpty(templates[template_idx].scales)) {
      let newTemplates = _.cloneDeep(templates)
      _.pullAt(newTemplates, [template_idx])
      setTemplates(newTemplates)
    }
  }

  function templateFields() {
    let fields = (templates || []).map((template, idx) => {
      let scaleFields = (template.scales || []).map((scale, s_idx) => {
        return (
          <TextField 
            name="scale"
            label=""
            hiddenLabel
            variant="filled"
            fullWidth
            margin="dense"
            value={scale}
            onChange={(e) => handleChangeScale(idx, s_idx, e.target.value)}
            onKeyUp={(e) => handleScaleKeyUp(idx, s_idx, e)}
            autoFocus
          />
        )
      })

      return (
        <div key={idx}>
          <TextField 
            name="title"
            label="Template Name"
            variant="filled"
            fullWidth
            margin="dense"
            value={template.name}
            onChange={(e) => handleChangeName(idx, e.target.value)}
            onKeyUp={(e) => handleNameKeyUp(idx, e)}
            autoFocus
          />
          <div className={classes.scaleFields}>
            {scaleFields}
            <Button color="secondary" onClick={() => addScale(idx)}>Add Scale</Button>
          </div>
        </div>
      )
    })

    return fields
  }

  return (
    <div>
      {templateFields()}
      <Box display="flex" mt={2} mb={1}>
        <Box flexGrow={1}>
          <Button color="primary" onClick={addTemplate}>Add Template</Button>
        </Box>
        <Button color="primary" variant="contained" onClick={saveChanges}>Save Changes</Button>
      </Box>
    </div>
  )
}