import {createStore, applyMiddleware} from 'redux'
import rootReducer from '../reducers'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import { save, load } from 'redux-localstorage-simple'
import { refreshToken, receiveLogout } from '../actions/authActions'
import thunk from 'redux-thunk'

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      function ({getState}, req) {
        req.headers.Authorization = `Bearer ${getState().auth.tokens.access_token}`

        return req
      },
    ],
    response: [{
      error: ({dispatch, getState}, error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
          if (isRefreshing) {
            return new Promise(function(resolve, reject) {
              failedQueue.push({resolve, reject})
            }).then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return axios(originalRequest)
            }).catch(err => {
              return Promise.reject(err)
            })
          }

          originalRequest._retry = true
          isRefreshing = true

          const refresh_token = getState().auth.tokens.refresh_token
          return new Promise(function(resolve, reject) {
            axios.post(`${process.env.REACT_APP_API_BASE}/refresh_token`, { refresh_token: refresh_token })
            .then(response => {
              let { access_token } = response.data
              dispatch(refreshToken(response.data))
              originalRequest.headers['Authorization'] = 'Bearer ' + access_token
              processQueue(null, access_token)
              resolve(axios(originalRequest))
            }).catch(err => {
              processQueue(err, null)
              dispatch(receiveLogout())
              reject(err)
            })
            .then(() => { isRefreshing = false })
          })
        }


  
        if ([401, 403].includes(error.response.status)) {
          
          // dispatch(flashErrorMessage("You have been logged out.  Please log in again."))
          dispatch(receiveLogout())
        } else {
          // for (var error_msg of _.get(error.response.data, ['errors'], [])) {
            // dispatch(flashErrorMessage(error_msg))
          // }
          return Promise.reject(error)
        }
      } 
    }]
  }
}

// const axiosMiddlewareConfig = {}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  responseType: 'json'
})

const createStoreWithMiddleware = applyMiddleware(
  save({ 
    states: ['auth', 'prefs'],
    namespace: 'iepcentre'
  }),
  axiosMiddleware(client, axiosMiddlewareConfig),
  thunk
)(createStore)


export default function configureStore() {
  return createStoreWithMiddleware(rootReducer, load({states: ['auth', 'prefs'], namespace: 'iepcentre', disableWarnings: true}), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}