import * as actionTypes from '../actions/actionTypes'

export const requestLogin = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
    isAuthenticated: false,
    isAuthenticating: true
  }
}

export const receiveLogin = (auth) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    isAuthenticated: true,
    isAuthenticating: false,
    user: auth.user,
    tokens: auth.tokens,
  }
}

export const refreshToken = (tokens) => {
  return {
    type: actionTypes.REFRESH_TOKEN,
    tokens: tokens
  }
}

export const loginError = (message) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    isAuthenticated: false,
    error: message
  }
}

export const receiveLogout = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    isAuthenticated: false,
    isAuthenticating: false,
    iat: undefined,
    user: null,
    token: null,
    customer: null
  }
}