import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import _ from 'lodash'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import * as authActions from '../actions/authActions'
import * as prefsActions from '../actions/prefsActions'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
// import axios from 'axios'
import { client } from '../AxiosClient'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import withMediaQuery from '../common/withMediaQuery'
import MenuIcon from '@mui/icons-material/Menu'
import logo from '../logo_xs_blue.svg'
import { withMsal } from "@azure/msal-react"
import withRouterHooks from '../common/withRouterHooks'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    marginTop: "0.2rem",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    }
  },
  school: {
    marginRight: theme.spacing(2)
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  mobileLogo: {
    height: 28,
    width: 28,
    marginBottom: "-0.2rem"
  }
})

class MainAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountMenuAnchorEl: null
    }

    this.handleAccountMenu = this.handleAccountMenu.bind(this)
    this.handleAccountClose = this.handleAccountClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleMenuSelection = this.handleMenuSelection.bind(this)

  }

  handleAccountMenu (event) {
    this.setState({ accountMenuAnchorEl: event.currentTarget })
  }

  handleAccountClose () {
    this.setState({ accountMenuAnchorEl: null })
  }

  async handleLogout () {
    if (this.props.auth.tokens.access_token) {
      
      await this.props.msalContext.instance.logoutRedirect({
        onRedirectNavigate: (url) => {
          return false
        }
      })

      client.post(`/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${this.props.auth.tokens.access_token}`
        }
      })
    


      this.props.receiveLogout()
      this.setState({ accountMenuAnchorEl: null })
    }
  }

  handleMenuSelection(url) {
    this.props.navigate(url)
    this.setState({ accountMenuAnchorEl: null })
  }

  render() {
    let { classes, mediaQuery, setMobileDrawerStatus, prefs } = this.props
    let isAuthenticated = _.get(this.props, "auth.isAuthenticated", false)
    let isAuthor = _.get(this.props, "auth.user.is_author", false)
    let isAdmin = _.get(this.props, "auth.user.is_admin", false)
    let open = Boolean(this.state.accountMenuAnchorEl)
    let school = _.get(this.props, "auth.user.school.name", null)

    let editOrShow = this.props.location.pathname.startsWith("/ieps/")

    if (!isAuthenticated) {
      return
    }

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h5">
            { (!mediaQuery.medium && editOrShow) ? (
              <IconButton
                color="inherit"
                onClick={() => setMobileDrawerStatus(!prefs.drawerOpen)}
                size="large">
                <MenuIcon />
              </IconButton>
            ) : (
              <Link component={RouterLink} to="/" color="inherit" underline="hover">
                { mediaQuery.medium ?
                  "IEP Centre"
                  :
                  <img alt="IEPCentre" src={logo} className={classes.mobileLogo} />
                }
              </Link>
            )}
          </Typography>
          <Typography variant="body1" className={classes.title}>
            { school &&
              <span className={classes.school}>
                <Link component={RouterLink} to="/" color="inherit" underline="hover">{school}</Link>
              </span>
            }
          </Typography>
          { isAuthenticated &&
            <React.Fragment>
              <IconButton
                aria-label="Current User Account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                name="account-menu"
                id="account-menu"
                onClick={this.handleAccountMenu}
                color="inherit"
                size="large">
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={this.state.accountMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleAccountClose}
              >
                <MenuItem onClick={() => this.handleMenuSelection('/account')}>Account Settings</MenuItem>
                { isAuthor &&
                  <MenuItem onClick={() => this.handleMenuSelection('/users')}>Manage Users</MenuItem>
                }
                { isAuthor &&
                  <MenuItem onClick={() => this.handleMenuSelection('/activity')}>Activity Report</MenuItem>
                }
                <Divider className={classes.divider} />
                { isAdmin &&
                  [
                    <MenuItem onClick={() => this.handleMenuSelection('/admin/template')} key="edit-template">Edit PDF Template</MenuItem>,
                    <Divider className={classes.divider} key="admin-divider" />
                  ]
                }
                <MenuItem onClick={this.handleLogout}>Log out</MenuItem>
              </Menu>
            </React.Fragment>
          }
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    prefs: state.prefs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    receiveLogout: () => dispatch(authActions.receiveLogout()),
    setMobileDrawerStatus: (open) => dispatch(prefsActions.setMobileDrawerStatus(open)),
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withMsal(withMediaQuery(withRouterHooks(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainAppBar)))))