import * as actionTypes from '../actions/actionTypes'
import jwt_decode from 'jwt-decode'

const Auth = (state = {
  isAuthenticated: false,
  isAuthenticating: false,
  user: undefined,
  tokens: { access_token: undefined, refresh_token: undefined },
  iat: undefined,
  maintenanceMessage: undefined
}, action) => {
  switch (action.type) {
  case actionTypes.LOGIN_REQUEST:
    return Object.assign({}, state, {
      isAuthenticated: false,
      isAuthenticating: true,
      tokens: { access_token: undefined, refresh_token: undefined }
    })
  case actionTypes.LOGIN_SUCCESS:
    return Object.assign({}, state, {
      isAuthenticated: true,
      isAuthenticating: false,
      errorMessage: '',
      user: { ...action.user,
        is_author: action.user.access_level > 0,
        is_admin: action.user.access_level > 1
      },
      tokens: action.tokens,
    })
  case actionTypes.LOGIN_ERROR:
    return Object.assign({}, state, {
      isAuthenticated: false,
      isAuthenticating: false,
      tokens: { access_token: undefined, refresh_token: undefined },
      errorMessage: action.error
    })
  case actionTypes.REFRESH_TOKEN: {
    if (state.isAuthenticated) {
      const decoded = jwt_decode(action.tokens.access_token)
      const exp = decoded.exp
      if (String(decoded.user_id) === String(state.user.id)) {
        return Object.assign({}, state, {
          exp: exp,
          tokens: action.tokens
        })
      } else {
        return state
      }
      
    } else {
      return { ...state, iat: null, tokens: { access_token: undefined, refresh_token: undefined } }
    }
  }
  case actionTypes.LOGOUT_SUCCESS:
    return Object.assign({}, state, {
      isAuthenticated: false,
      isAuthenticating: false,
      errorMessage: '',
      user: null,
      tokens: { access_token: undefined, refresh_token: undefined },
      iat: null
    })
  default:
    return state
  }
}

export default Auth