import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextField, Button } from '@mui/material'
import { useEffect } from 'react'
import { update } from '../actions/userActions'

function Account(props) {
  const user = useSelector(state => state.user)
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dirty, setDirty] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setEmail(user.email)
    setFirstName(user.first_name)
    setLastName(user.last_name)
  }, [user])
  
  function handleChange(e) {
    switch(e.target.name) {
      case "email":
        setEmail(e.target.value)
        break
      case "first_name":
        setFirstName(e.target.value)
        break
      case "last_name":
        setLastName(e.target.value)
        break
      default:
        break
    }
    setDirty(true)
  }

  function handleSave() {
    setDirty(false)
    dispatch(update({ email: email, first_name: firstName, last_name: lastName }))
  }

  return (
    <div>
      <TextField fullWidth variant="filled" margin="normal" value={email} label="Email" onChange={handleChange} name="email" />
      <TextField fullWidth variant="filled" margin="normal" value={firstName} label="First Name" onChange={handleChange} name="first_name" />
      <TextField fullWidth variant="filled" margin="normal" value={lastName} label="Last Name" onChange={handleChange} name="last_name" />
      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave} disabled={!dirty}>Update Account Details</Button>
    </div>
  )
}

export default Account