import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import './transitions.css'

import { grey } from '@mui/material/colors';

const useStyles = makeStyles({
  root: {
    minHeight: "calc(75vh - 64px)",
    height: "calc(75vh - 64px)"
  },
  loading: {
    textAlign: "center",
    color: grey[500]
  },
  loadingProgress: {
    marginTop: 40,
    color: "rbga(0, 0, 0, 0.2)"
  },
})


export default function LoadingDisplay() {
  const [inProp, setInProp] = useState(false)
  let classes = useStyles()

  useEffect(() => {
    const timeout = setTimeout(() => { setInProp(true) }, 0) 

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <CSSTransition 
      in={inProp}
      appear
      timeout={10000}
      classNames='fade'
    >
      <Grid container justifyContent="center" alignItems="center" className={classes.root}>
        <Grid item xs={8} sm={6} md={3} className={classes.loading}>
          <CircularProgress color="inherit" className={classes.loadingProgress} />
          <Typography color="inherit" className={classes.loadingText}>Loading...</Typography>
        </Grid>
      </Grid>    
    </CSSTransition>
  )
}