import React from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import WarningIcon from '@mui/icons-material/Warning'
import Typography from '@mui/material/Typography'

import './transitions.css'

const styles = theme => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    color: "#ccc"
  },
  errorMsg: {
    marginTop: theme.spacing(2)
  }
})

class ErrorDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      in: false
    }
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ in: true }) }, 0) 
  }

  render () {
    let { classes } = this.props
    let msg = this.props.message || "An error has occurred loading this component.  Please reload the page to try again."
    
    return (
      <CSSTransition 
        in={this.state.in}
        appear
        timeout={10000}
        classNames='fade'
      >
        <div className={classes.root}>
          <WarningIcon fontSize="large" />
          <Typography variant="h5" className={classes.errorMsg}>{msg}</Typography>
          { this.props.secondaryMessage &&
            <Typography variant="subtitle1">{this.props.secondaryMessage}</Typography>
          }
        </div>
      </CSSTransition>
    )
  }
}

ErrorDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ErrorDisplay)