import * as actionTypes from '../actions/actionTypes'

const Dashboard = (state = {
  recent_users: [],
  logs: [],
  db_stats: {}
}, action) => {
  switch (action.type) {
  case actionTypes.DASHBOARD_RECENT_USERS:
    return { ...state, 
      recent_users: []
    }
  case actionTypes.DASHBOARD_RECENT_USERS.SUCCESS:
    return { ...state,
      recent_users: action.payload.data
    }
  case actionTypes.DASHBOARD_RECENT_USERS.FAIL:
    return { ...state,
      recent_users: []
    }
  case actionTypes.DASHBOARD_LOGS:
    return { ...state, 
      logs: []
    }
  case actionTypes.DASHBOARD_LOGS.SUCCESS:
    return { ...state,
      logs: action.payload.data
    }
  case actionTypes.DASHBOARD_LOGS.FAIL:
    return { ...state,
      logs: []
    }
  case actionTypes.DASHBOARD_DB_STATS:
    return { ...state, 
      db_stats: {}
    }
  case actionTypes.DASHBOARD_DB_STATS.SUCCESS:
    return { ...state,
      db_stats: action.payload.data
    }
  case actionTypes.DASHBOARD_DB_STATS.FAIL:
    return { ...state,
      db_stats: {}
    }
  default:
    return state
  }
}

export default Dashboard