import * as actionTypes from '../actions/actionTypes'

export const update = (user) => (dispatch) => {
  var request_url = `/user`

  dispatch({
    type: actionTypes.USER_UPDATE,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: user
      }
    }
  })
}

export const show = () => (dispatch) => {
  var request_url = `/user`

  dispatch({
    type: actionTypes.USER_SHOW,
    payload: {
      request: {
        url: request_url,
      }
    }
  })
}

export const uploadAvatar = (file) => (dispatch) => {
  var request_url = `/user`

  var formData = new FormData()
  formData.append('photo', file)

  dispatch({
    type: actionTypes.USER_UPLOAD_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: formData
      }
    }
  })
}

export const uploadThumbnail = (file) => (dispatch) => {
  var request_url = `/user`

  var formData = new FormData()
  formData.append('thumbnail', file)

  dispatch({
    type: actionTypes.USER_UPLOAD_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: formData
      }
    }
  })
}

export const deleteAvatar = () => (dispatch) => {
  var request_url = `/user`

  dispatch({
    type: actionTypes.USER_DELETE_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { _delete_avatar: true }
      }
    }
  })
}

export const changePassword = (current_password, password) => (dispatch) => {
  var request_url = `/user/password`

  dispatch({
    type: actionTypes.USER_CHANGE_PASSWORD,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { 
          password: current_password,
          new_password: password
        }
      }
    }
  })
}

export const flushMessages = () => {
  return {
    type: actionTypes.USER_FLUSH_MESSAGES
  }
}