import React from 'react'
import { connect } from 'react-redux'
import { client } from './AxiosClient'
import { Box, Typography, Button } from '@mui/material'
import { WarningAmber, BrowserUpdated } from '@mui/icons-material'
import { amber, blue } from '@mui/material/colors'

import _ from 'lodash'

const chunkFailedMessage = /Loading chunk [\d]+ failed/

class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            hasError: false,
            chunkError: false
        }
    }


    static getDerivedStateFromError(error) {
        let chunkError = (error?.message && chunkFailedMessage.test(error.message))
        return { hasError: true, chunkError: chunkError }
    }

    componentDidCatch(error, errorInfo) {
        if (!(error?.message && chunkFailedMessage.test(error.message))) {
            // forces a logout if it isn't a chunk loading error
            localStorage.clear()        
        }

        client.post(`/log_error`, {
            message: error.message,
            trace: errorInfo.componentStack,
            is_authenticated: this.props.auth.isAuthenticated,
            user_id: this.props.auth.user_id,
            access_token: _.get(this.props.auth, "tokens.access_token"),
            refresh_token: _.get(this.props.auth, "tokens.refresh_token"),
            url: window.location.href
        })
    }

    render() {
        if (this.state.chunkError) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "67vh"}}>
                    <BrowserUpdated sx={{ fontSize: "60pt" }} color="primary" />
                    <Typography variant="h3" sx={{ mb: 2 }} color="primary" >IEPCentre Update</Typography>
                    <Typography variant="body2" color="gray">IEPCentre has been updated.  Please reload this page to download the latest version.</Typography>
                    <Typography variant="body2" color="gray">If the error persists, please <a href="mailto:dave@geckodigital.ca">contact us</a> for next steps.</Typography>
                    <Button color="primary" variant="contained" sx={{ my: 5 }} onClick={ () => { window.location.reload() } }>Refresh Now</Button>
                </Box>
            )
        }

        if (this.state.hasError) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "67vh"}}>
                    <WarningAmber sx={{ fontSize: "60pt", color: amber[500] }} />
                    <Typography variant="h3" sx={{ mb: 2 }} >Whoops!</Typography>
                    <Typography variant="body2">An error has occurred in IEPCentre.  Our site administrators have been notified of the error.</Typography>
                    <Typography variant="body2">If the error persists, please <a href="mailto:dave@geckodigital.ca">contact us</a> for next steps.</Typography>
                </Box>
            )
        }

        return this.props.children
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

export default connect(mapStateToProps, null)(AppErrorBoundary)