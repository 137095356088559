import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash'

const Templates = (state = {
  template: {},
  status: "not_loaded",
  retries: 0,
  messages: []
}, action) => {
  switch(action.type) {
  case actionTypes.TEMPLATES_FETCH:
    return { ...state, 
      template: {},
      status: "loading",
    }
  case actionTypes.TEMPLATES_FETCH.SUCCESS:
    return { ...state,
      template: _.get(action.payload, "data", {}),
      status: "loaded"
    }
  case actionTypes.TEMPLATES_FETCH.FAIL:
    return { ...state,
      template: {},
      status: "error",
      retries: state.retries + 1,
      messages: [...state.messages,
        { message: "Could not fetch template - an error occurred", type: "error" }
      ]
    }
  case actionTypes.TEMPLATES_UPDATE:
    return { ...state, 
      template_status: "updating",
    }
  case actionTypes.TEMPLATES_UPDATE.SUCCESS:
    return { ...state,
      template: _.get(action.payload, "data", {}),
      status: "loaded",
      retries: 0,
      messages: [...state.messages,
        { message: "Template saved successfully", type: "success" }
      ] 
    }
  case actionTypes.TEMPLATES_UPDATE.FAIL:
    return { ...state,
      errors: [ ...state.errors, action.meta.previousAction.key ],
      status: "error",
      retries: state.retries + 1,
      messages: [...state.messages,
        { message: "Could not update template - an error occurred", type: "error" }
      ] 
    }
  case actionTypes.TEMPLATES_FLUSH_MESSAGES:
    return { ...state,
      messages: []
    }
  default:
    return state
  }
}

export default Templates