import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { TextField, Button } from '@mui/material'
import { changePassword } from '../actions/userActions'

function ChangePassword(props) {
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const dispatch = useDispatch()
  
  let passwordError = (newPassword.length > 0 && newPassword.length < 8)
  let passwordConfirmationError = (!passwordError && newPassword !== newPasswordConfirmation)
  let readyToSave = (currentPassword.length > 0 && newPassword.length > 0 && !passwordError && !passwordConfirmationError)

  function handleChange(e) {
    switch(e.target.name) {
      case "current_password":
        setCurrentPassword(e.target.value)
        break
      case "new_password":
        setNewPassword(e.target.value)
        break
      case "new_password_confirmation":
        setNewPasswordConfirmation(e.target.value)
        break
      default:
        break
    }
  }

  function handleSave() {
    dispatch(changePassword(currentPassword, newPassword))
    setCurrentPassword("")
    setNewPassword("")
    setNewPasswordConfirmation("")
  }

  return (
    <div>
      <TextField type="password" onChange={handleChange} fullWidth variant="filled" margin="normal" value={currentPassword} label="Current password" name="current_password" />
      <TextField error={passwordError} helperText={passwordError ? "Password too short (min 8 characters)" : null} type="password" onChange={handleChange} fullWidth variant="filled" margin="normal" value={newPassword} label="New password" name="new_password" />
      <TextField error={passwordConfirmationError} helperText={passwordConfirmationError ? "Passwords do not match" : null} type="password" onChange={handleChange} fullWidth variant="filled" margin="normal" value={newPasswordConfirmation} label="Confirm new password" name="new_password_confirmation" />
      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave} disabled={!readyToSave}>Change Password</Button>
    </div>
  )
}

export default ChangePassword