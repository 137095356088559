import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useLocation, useNavigate } from "react-router"
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
import { client } from '../AxiosClient'
import Box from '@mui/material/Box'

// const useStyles = makeStyles(theme => ({
//   main: {
//     width: 'auto',
//     display: 'block', // Fix IE 11 issue.
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3),
//     [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
//       width: 400,
//       marginLeft: 'auto',
//       marginRight: 'auto',
//     },
//   },
//   paper: {
//     marginTop: 96,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: theme.spacing(2, 3, 3, 3),
//   },
//   heading: {
//     marginBottom: theme.spacing(2),
//   },
//   field: {
//     marginTop: theme.spacing(2)
//   },
//   button: {
//     marginTop: theme.spacing(2)
//   }
// }))

export default function LoginForm() {
  const [password, setPassword] = useState("")
  const [confirmation, setConfirmation] = useState("")
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = queryString.parse(location.search)
  const { enqueueSnackbar } = useSnackbar()

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleConfirmChange = (event) => {
    setConfirmation(event.target.value)
  }

  const handleSubmit = () => {
    client.post(`/reset_password`, { token: token, password: password, password_confirmation: confirmation })
      .then(() => {
        navigate("/")
        enqueueSnackbar("Password has been successfully reset.", { variant: "success" })
      }).catch(error => {
        navigate("/forgot_password")
        enqueueSnackbar("Password could not be reset.  Please try again.", { variant: "error" })

        // var errors = [_.get(error.response, "data.errors")].flat()
        // for (const err of errors) {
        //   enqueueSnackbar(err, { variant: "error" })
        // }
      })
  }

  let passwordError = (password.length > 0 && password.length < 8)
  let passwordConfirmationError = (!passwordError && confirmation.length > 0 && password !== confirmation)
  let readyToSave = (password.length > 0 && confirmation.length > 0 && !passwordError && !passwordConfirmationError)

  return (
    <main>
      <Box sx={{
        width: {
          xs: "100%",
          sm: 400
        },
        mx: 'auto',
        marginTop: "72px"
      }}>
        <Paper sx={{ pt: 2, px: 3, pb: 3 }}>
          <Typography component="h1" variant="h5" sx={{mb: 2}}>
            Reset Password
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}}>
            Please enter a new password.  Choose a strong password, with at least 8 characters, and a mix of uppercase, lowercase, numbers, or symbols.
          </Typography>
          <form>
            <TextField sx={{mt: 2}} name="password" autoComplete="new-password" type="password" error={passwordError} helperText={passwordError ? "Password too short (min 8 characters)" : null} variant="outlined" label="New password" fullWidth size="small" value={password} onChange={handlePasswordChange} />
            <TextField sx={{mt: 1, mb: 2}} name="password_confirmation" autoComplete="new-password" type="password" error={passwordConfirmationError} helperText={passwordConfirmationError ? "Passwords do not match" : null} variant="outlined" label="Confirm new password" fullWidth size="small" value={confirmation} onChange={handleConfirmChange} />
            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!readyToSave}>Reset Password</Button>
          </form>
        </Paper>
      </Box>
    </main>
  )
}