import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import * as actionTypes from './actions/actionTypes'

export default function Messenger() {
  const { enqueueSnackbar } = useSnackbar()
  const userMessages = useSelector(state => state.user.messages)
  const iepMessages = useSelector(state => state.iep.messages)
  const templatesMessages = useSelector(state => state.templates.messages)
  const usersMessages = useSelector(state => state.users.messages)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userMessages.length > 0) {
      userMessages.forEach( msg => enqueueSnackbar(msg.message, { variant: msg.type, preventDuplicate: true }) )
      dispatch({ type: actionTypes.USER_FLUSH_MESSAGES })
    }

    if (iepMessages.length > 0) {
      iepMessages.forEach( msg => enqueueSnackbar(msg.message, { variant: msg.type, preventDuplicate: true }) )
      dispatch({ type: actionTypes.IEP_FLUSH_MESSAGES })      
    }

    if (templatesMessages.length > 0) {
      templatesMessages.forEach( msg => enqueueSnackbar(msg.message, { variant: msg.type, preventDuplicate: true }) )
      dispatch({ type: actionTypes.TEMPLATES_FLUSH_MESSAGES })      
    }

    if (usersMessages.length > 0) {
      usersMessages.forEach( msg => enqueueSnackbar(msg.message, { variant: msg.type, preventDuplicate: true }) )
      dispatch({ type: actionTypes.USERS_FLUSH_MESSAGES })      
    }
  })

  return null
}
