import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash'

const User = (state = {
  status: "not_loaded",
  updating: [],
  errors: [],
  messages: [],
}, action) => {
  switch (action.type) {
  case actionTypes.USER_SHOW:
    return { ...state,
      status: "loading",
    }
  case actionTypes.USER_SHOW.SUCCESS:
    return { ...state,
      ...action.payload.data,
      status: "loaded"
    }
  case actionTypes.USER_SHOW.FAIL:
    return { ...state,
      status: "error"
    }
  case actionTypes.USER_UPDATE:
    return { ...state,
      status: "loading",
    }
  case actionTypes.USER_UPDATE.SUCCESS:
    return { ...state,
      ...action.payload.data,
      status: "loaded",
      messages: [...state.messages,
        { message: "Account details updated", type: "success" }
      ]
    }
  case actionTypes.USER_UPDATE.FAIL:
    return { ...state,
      ...action.payload.data,
      status: "error",
      messages: [...state.messages,
        { message: "Account details could not be updated - an error occurred", type: "error" }
      ]
    }
  case actionTypes.USER_UPLOAD_AVATAR:
  case actionTypes.USER_DELETE_AVATAR:
    return { ...state, 
      updating: [ ...state.updating, 'avatar' ],
      errors: _.without(state.errors, 'avatar'),
      status: "updating",
    }
  case actionTypes.USER_UPLOAD_AVATAR.SUCCESS:
  case actionTypes.USER_DELETE_AVATAR.SUCCESS:
    return { ...state,
      ...action.payload.data,
      updating: _.without(state.updating, 'avatar'),
      status: "loaded",
      avatar_retries: 0,
      messages: [...state.messages,
        { message: "Avatar updated", type: "success" }
      ]   
    }
  case actionTypes.USER_UPLOAD_AVATAR.FAIL:
  case actionTypes.USER_DELETE_AVATAR.FAIL:
    return { ...state,
      errors: [ ...state.errors, 'avatar' ],
      status: "error",
      avatar_retries: state.avatar_retries + 1,
      messages: [...state.messages,
        { message: "Failed to edit avatar - an error occurred.", type: "error" }
      ]   
    }
  case actionTypes.USER_CHANGE_PASSWORD.SUCCESS:
    return { ...state,
      messages: [...state.messages,
        { message: "Password successfully changed", type: "success" }
      ]
    }
  case actionTypes.USER_CHANGE_PASSWORD.FAIL: {
    let fail_messages = _.get(action.error, ["response", "data", "errors"], ["Could not change password"]).map( err => { return { message: err, type: "error" } } )
    return { ...state,
      messages: [...state.messages,
        ...fail_messages
      ]
    }
  }
  case actionTypes.USER_FLUSH_MESSAGES:
    return { ...state,
      messages: []
    }
  default:
    return state
  }
}

export default User