import React, { Fragment, useState, useRef } from 'react'
import { EditTwoTone } from '@mui/icons-material'
import { Dialog, Button, DialogTitle, DialogContent, DialogActions, Tooltip, IconButton, Slider } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { uploadThumbnail } from '../actions/userActions'
import ReactAvatarEditor from 'react-avatar-editor'

function AvatarEditor(props) {
    const [isEditing, setIsEditing] = useState(false)
    const [scale, setScale] = useState(20)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const editorEl = useRef(null)

    function handleOpen() {
        console.log("Opening url:", user.photo_url)
        setIsEditing(true)
    }

    function handleSave() {
        const canvas = editorEl.current.getImage()
        canvas.crossOrigin = 'anonymous'
        setIsEditing(false)
        canvas.toBlob((blob) => {
            dispatch(uploadThumbnail(blob))
        }, 'image/jpeg', 0.8)
    }

    function handleClose() {
        setIsEditing(false)
    }

    function handleZoom(event) {
        setScale(event.target.value)
    }

    return (
        <Fragment>
            <Tooltip title="Edit">
                <IconButton 
                    size="small"
                    onClick={handleOpen}
                >
                    <EditTwoTone sx={{ color: "white" }} />
                </IconButton>
            </Tooltip>
            <Dialog open={isEditing} onClose={handleClose}>
                <DialogTitle>Edit Profile Picture</DialogTitle>
                <DialogContent>
                    <ReactAvatarEditor 
                        ref={editorEl}
                        image={user.photo_url} 
                        width={200}
                        height={200}
                        border={40}
                        color={[0, 0, 0, 0.5]}
                        scale={(scale / 40.0) + 0.5}
                        borderRadius={10}
                        crossOrigin='anonymous'
                    />
                    <Slider value={scale} onChange={handleZoom} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
        
            
    )
}

export default AvatarEditor