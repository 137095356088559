import React, { useEffect, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { Button } from '@mui/material';

const action = snackbarId => (
    <React.Fragment>
      <Button color="secondary" variant="contained" size="small" onClick={() => { window.location.reload() }}>
        Refresh Now
      </Button>
    </React.Fragment>
);

function VersionCheck(props) {
    const { enqueueSnackbar } = useSnackbar()
    const frontEndVersion = Number(document.getElementsByName("iep:version")?.[0]?.content)

    const checkVersion = useCallback(() => {
        axios.get('/version.txt').then((res) => {
            const currentVersion = Number(res.data)
            if (frontEndVersion < currentVersion) {
                enqueueSnackbar("IEPCentre updates are available.  Please refresh now to continue working in IEPCentre.", { preventDuplicate: true, variant: "default", action, autoHideDuration: null })
            }
        }).catch((error) => {
            console.log("Error fetching version from server", error)
        })
    }, [enqueueSnackbar, frontEndVersion])

    useEffect(() => {
        checkVersion()
        const intervalId = setInterval(checkVersion, 120000)
        return () => { clearInterval(intervalId) }
    }, [checkVersion])

    return (
        <React.Fragment />
    )
}

export default VersionCheck