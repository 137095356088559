import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./msalConfig"
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import { TourProvider } from '@reactour/tour'

const store = configureStore()
const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById('root')
const root = createRoot(container)

const theme = createTheme({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  palette: {
    primary: {
      main: '#00B2C0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EF7327',
      contrastText: '#fff',
    },
  },
  zIndex: {
    mobileStepper: 700,
    appBar: 900,
    drawer: 800,
    modal: 1000,
    snackbar: 1100,
    tooltip: 1200,
  },
  typography: {
    h3: {
      fontSize: '2rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500
    }
  },
})

root.render(
  <React.Fragment>
    <TourProvider>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Router>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
          </Router>
        </Provider>
      </MsalProvider>
    </TourProvider>
  </React.Fragment>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
