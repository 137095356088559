import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import { Grid, Backdrop, CircularProgress, Container } from '@mui/material'
import IepSection from '../common/IepSection'
import Account from './Account'
import * as iepActions from '../actions/iepActions'
import * as userActions from '../actions/userActions'
import Avatar from './Avatar'
import ChangePassword from './ChangePassword'
import Preferences from './Preferences'
import _ from 'lodash'

const styles = theme => ({
  root: {
    marginTop: '72px',
  },
})

class AccountSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    if (this.props.is_author) {
      this.props.fetchUserData()
    }
    this.props.showUser()
  }

  renderSection(type, status) {
    var sec = undefined
    var title = ""
    var sectionProps = {}
    
    switch(type) {
    case "account":
      sec = <Account />
      title = "Account Details"
      break
    case "avatar":
      sec = <Avatar />
      sectionProps.noPadding = true
      title = "Profile Picture"
      break
    case "password":
      sec = <ChangePassword />
      title = "Change Password"
      break
    case "preferences":
      sec = <Preferences />
      title = "Preferences"
      break
    default:
      sec = null 
    }
        
    return <IepSection title={title} {...sectionProps} >
      {sec}
    </IepSection>
  }

  render() {
    let { classes, user, is_author } = this.props
    
    return (
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            { this.renderSection("account", user.status) }
          </Grid>
          <Grid item xs={12} md={5}>
            { this.renderSection("password", user.status) }
          </Grid>
          <Grid item xs={12} md={2}>
            { this.renderSection("avatar", user.status) }
          </Grid>
          { is_author &&
            <React.Fragment>
              <Grid item xs={12}>
                { this.renderSection("preferences", user.status) }
              </Grid>
            </React.Fragment>
          }
        </Grid>
        { ["loading", "updating"].includes(user.status) &&
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      </Container>
    )
  }
}

AccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    data_status: state.iep.data_status,
    data: state.iep.data,
    user: state.user,
    is_author: _.get(state, "auth.user.is_author", false)
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserData: () => dispatch(iepActions.fetchUserData()),
    showUser: () => dispatch(userActions.show()),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AccountSettings))