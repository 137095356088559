import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import LoginForm from './LoginForm'
import MainAppBar from './app_bar/MainAppBar'
import makeStyles from '@mui/styles/makeStyles';
import { CssBaseline } from '@mui/material'
import ServicesView from './services/ServicesView'
import AssessmentTemplatesView from './assessment_templates/AssessmentTemplatesView'
import AccountSettings from './settings/AccountSettings'
import { SnackbarProvider } from 'notistack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Messenger from './Messenger'
import ForgotPassword from './password/ForgotPassword'
import ResetPassword from './password/ResetPassword'
import './FontAwesomeIconLibrary'
import LoadingDisplay from './common/LoadingDisplay'
import { ConfirmProvider } from 'material-ui-confirm'
// import ActivityReportView from './activity_report/ActivityReportView'
// import HealthCheck from './HealthCheck'
import { useSelector } from 'react-redux'
import AppErrorBoundary from './AppErrorBoundary'
import VersionCheck from './VersionCheck'

const Home = lazy(() => import('./home/CardHome'))
const Edit = lazy(() => import('./edit/Edit'))
const Show = lazy(() => import('./show/Show'))
const Users = lazy(() => import('./users/Users'))
const TemplateView = lazy(() => import('./template/TemplateView'))
const Dashboard = lazy(() => import('./dashboard/Dashboard'))
const ActivityReportView = lazy(() => import('./activity_report/ActivityReportView'))


const useStyles = makeStyles( (theme) => {
  return {
    success: {
      backgroundColor: "#7DBE42"
    }
  }
})

const RequireAuth = React.memo( function RequireAuth({ children, admin, author }) {
  const auth = useSelector(state => state.auth)

  var isAuthenticated = auth.isAuthenticated && auth.tokens.access_token

  if (author && !auth?.user?.is_author) {
    isAuthenticated = false
  }

  if (admin && !auth?.user?.is_admin) {
    isAuthenticated = false
  }

  return isAuthenticated ? children : <Navigate to="/login" />
})

function App(props) {
  const notistackRef = React.createRef();
  const classes = useStyles()

  function onClickDismiss(key) {
    notistackRef.current.closeSnackbar(key)
  }

  return (
      <AppErrorBoundary>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            variantSuccess: classes.success
          }}
          maxSnack={3}
          preventDuplicate
          action={(key) => <IconButton color="inherit" size="small" onClick={() => { onClickDismiss(key) } }><CloseIcon /></IconButton>}
        >
          <ConfirmProvider>
            <Messenger />
            <CssBaseline />
            <MainAppBar {...props} mobileLogo={props.mobileLogo} />
            <div style={{ marginTop: 48 }}>
              <VersionCheck />
              <Suspense fallback={<LoadingDisplay />}>
                <Routes>
                    <Route path="/login" element={<LoginForm {...props} logoUrl={props.logoUrl} />} />
                    <Route path="/forgot_password" element={<ForgotPassword />} />
                    <Route path="/password_reset" element={<ResetPassword />} />
                    <Route path="/ieps/:id/edit/*" element={<RequireAuth author><Edit /></RequireAuth>} />
                    <Route path="/ieps/:id/*" element={<RequireAuth><Show /></RequireAuth>} />
                    <Route path="/services" element={<RequireAuth author><ServicesView /></RequireAuth>}/>
                    <Route path="/assessment_templates" element={<RequireAuth author><AssessmentTemplatesView /></RequireAuth>} />
                    <Route exact path="/account" element={<RequireAuth><AccountSettings /></RequireAuth>} />
                    <Route exact path="/users" element={<RequireAuth author><Users /></RequireAuth>} />
                    <Route exact path="/activity" element={<RequireAuth author><ActivityReportView /></RequireAuth>} />
                    <Route exact path="/admin/template" element={<RequireAuth admin><TemplateView /></RequireAuth>} />
                    <Route exact path="/admin/dashboard" element={<RequireAuth admin><Dashboard /></RequireAuth>} />
                    <Route path="/*" element={<RequireAuth><Home /></RequireAuth>} />
                </Routes>
              </Suspense>
            </div>
          </ConfirmProvider>
        </SnackbarProvider>   
    </AppErrorBoundary>
  )
}  

export default App