import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faInfoCircle, 
  faRuler,
  faUserCircle,
  faBullseye,
  faSlidersH,
  faSuitcase,
  faChalkboardTeacher,
  faPaperclip,
  faComments,
  faEdit,
  faEye,
  faHome,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faInfoCircle, 
  faRuler, 
  faUserCircle, 
  faBullseye, 
  faSlidersH, 
  faSuitcase, 
  faChalkboardTeacher,
  faPaperclip,
  faComments,
  faEdit,
  faEye,
  faHome
)
