import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import * as iepActions from '../actions/iepActions'
import AssessmentTemplatesEditor from './AssessmentTemplatesEditor'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
})

class AssessmentTemplatesView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.handleSave = this.handleSave.bind(this)
  }

  componentDidMount() {
    // this.props.fetchUserData()
  }

  handleSave() {
    // this.props.fetchUserData()
  }

  render() {
    let { classes } = this.props
    return (
      <Container className={classes.root}>
        <Typography variant="h3">Assessment Templates</Typography>
        <AssessmentTemplatesEditor templates={this.props.data.assessment_templates} onSave={this.handleSave} />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ...state.iep

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserData: () => dispatch(iepActions.fetchUserData()),
  }
}

AssessmentTemplatesView.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AssessmentTemplatesView))