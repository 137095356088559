import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { TextField, Button } from '@mui/material'
import { update } from '../actions/userActions'

function Preferences(props) {
  const preferences = useSelector(state => state.user.preferences)
  const [currentSchoolYear, setCurrentSchoolYear] = useState("")
  const [defaultAdaptationCategories, setDefaultAdaptationCategories] = useState([])
  const [dirty, setDirty] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (preferences) {
      setCurrentSchoolYear(preferences.current_school_year)
      setDefaultAdaptationCategories((preferences.default_adaptation_categories || []).join("\n"))
    }
  }, [preferences])

  function handleChangeCurrentSchoolYear(e) {
    setCurrentSchoolYear(e.target.value)
    setDirty(true)
  }

  function handleChangeDefaultAdaptationCategories(e) {
    setDefaultAdaptationCategories(e.target.value)
    setDirty(true)
  }

  function handleSave() {
    dispatch(update({ preferences: { ...preferences, current_school_year: currentSchoolYear, default_adaptation_categories: defaultAdaptationCategories.split("\n") } }))
    setDirty(false)
  }

  return (
    <div>
      <TextField 
        fullWidth 
        variant="filled" 
        margin="normal" 
        value={currentSchoolYear} 
        label="Current School Year" 
        name="current_school_year"
        helperText="This will override the date at the top of any generated PDFs."
        onChange={handleChangeCurrentSchoolYear} />
      <TextField
        fullWidth
        multiline
        variant="filled"
        margin="normal"
        value={defaultAdaptationCategories}
        label="Default Adaptation Categories"
        name="default_adaptation_categories"
        helperText="List the categories (one per line) you most commonly use under Adaptations in new IEPs."
        onChange={handleChangeDefaultAdaptationCategories} />
      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave} disabled={!dirty}>Update User</Button>
    </div>
  )
}


export default Preferences