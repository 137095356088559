import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import * as iepActions from '../actions/iepActions'
import ServicesEditor from './ServicesEditor'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
})

class ServicesView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.props.fetchUserData()

  }

  render() {
    let { classes } = this.props
    return (
      <Container className={classes.root}>
        <Typography variant="h3">Services</Typography>
        { this.props.data.services && 
            <ServicesEditor services={this.props.data.services} />
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ...state.iep

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserData: () => dispatch(iepActions.fetchUserData()),
  }
}

ServicesView.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ServicesView))