import { defineAction } from 'redux-define'

export const FAIL     = 'FAIL'
export const SUCCESS   = 'SUCCESS'

const auth = defineAction('AUTH')
export const LOGIN_REQUEST = auth.defineAction('LOGIN_REQUEST')
export const LOGIN_SUCCESS = auth.defineAction('LOGIN_SUCCESS')
export const LOGIN_ERROR = auth.defineAction('LOGIN_ERROR')
export const REFRESH_TOKEN = auth.defineAction('REFRESH_TOKEN')
export const LOGOUT_SUCCESS = auth.defineAction('LOGOUT_SUCCESS')

const iep = defineAction('IEP')
export const IEP_FETCH_INDEX = iep.defineAction('FETCH_INDEX', ['SUCCESS', 'FAIL'])
export const IEP_FETCH = iep.defineAction('FETCH', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE = iep.defineAction('UPDATE', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE_VALUE = iep.defineAction('UPDATE_VALUE')
export const IEP_DISCARD_CHANGES = iep.defineAction('DISCARD_CHANGES')
export const IEP_SAVE = iep.defineAction('SAVE', ['SUCCESS', 'FAIL'])
export const IEP_USER_DATA = iep.defineAction('USER_DATA', ['SUCCESS', 'FAIL'])
export const IEP_FETCH_COMMENTS = iep.defineAction('FETCH_COMMENTS', ['SUCCESS', 'FAIL'])
export const IEP_CREATE_COMMENT = iep.defineAction('CREATE_COMMENT', ['SUCCESS', 'FAIL'])
export const IEP_DELETE_COMMENT = iep.defineAction('DELETE_COMMENT', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE_COMMENT = iep.defineAction('UPDATE_COMMENT', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE_PERFORMANCE_TEST = iep.defineAction('UPDATE_PERFORMANCE_TEST', ['SUCCESS', 'FAIL'])
export const IEP_ADD_PERFORMANCE_TEST = iep.defineAction('ADD_PERFORMANCE_TEST', ['SUCCESS', 'FAIL'])
export const IEP_DELETE_PERFORMANCE_TEST = iep.defineAction('DELETE_PERFORMANCE_TEST', ['SUCCESS', 'FAIL'])
export const IEP_REORDER_PERFORMANCE_TEST = iep.defineAction('REORDER_PERFORMANCE_TEST', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE_SERVICES = iep.defineAction('UPDATE_SERVICES', ['SUCCESS', 'FAIL'])
export const IEP_UPDATE_ASSESSMENT_TEMPLATES = iep.defineAction('UPDATE_ASSESSMENT_TEMPLATES', ['SUCCESS', 'FAIL'])
export const IEP_UPLOAD_AVATAR = iep.defineAction('UPLOAD_AVATAR', ['SUCCESS', 'FAIL'])
export const IEP_DELETE_AVATAR = iep.defineAction('DELETE_AVATAR', ['SUCCESS', 'FAIL'])
export const IEP_FETCH_DOCUMENTS = iep.defineAction('FETCH_DOCUMENTS', ['SUCCESS', 'FAIL'])
export const IEP_UPLOAD_DOCUMENT = iep.defineAction('UPLOAD_DOCUMENT', ['SUCCESS', 'FAIL'])
export const IEP_DELETE_DOCUMENT = iep.defineAction('DELETE_DOCUMENT', ['SUCCESS', 'FAIL'])
export const IEP_STASH_DOCUMENT = iep.defineAction('STASH_DOCUMENT', ['SUCCESS', 'FAIL'])
export const IEP_FLUSH_MESSAGES = iep.defineAction('FLUSH_MESSAGES')

const prefs = defineAction('PREFS')
export const PREFS_SET_TAB = prefs.defineAction('SET_TAB')
export const PREFS_SET_DRAWER_STATUS = prefs.defineAction('SET_DRAWER_STATUS')
export const PREFS_SET_MOBILE_DRAWER_STATUS = prefs.defineAction('SET_MOBILE_DRAWER_STATUS')
export const PREFS_SET_SEARCH_STRING = prefs.defineAction('SET_SEARCH_STRING')
export const PREFS_SET_SCOPE = prefs.defineAction('SET_SCOPE')

const user = defineAction('USER')
export const USER_UPDATE = user.defineAction('UPDATE', ['SUCCESS', 'FAIL'])
export const USER_SHOW = user.defineAction('SHOW', ['SUCCESS', 'FAIL'])
export const USER_UPLOAD_AVATAR = user.defineAction('UPLOAD_AVATAR', ['SUCCESS', 'FAIL'])
export const USER_DELETE_AVATAR = user.defineAction('DELETE_AVATAR', ['SUCCESS', 'FAIL'])
export const USER_CHANGE_PASSWORD = user.defineAction('CHANGE_PASSWORD', ['SUCCESS', 'FAIL'])
export const USER_FLUSH_MESSAGES = user.defineAction('FLUSH_MESSAGES')

const users = defineAction('USERS')
export const USERS_INDEX = users.defineAction('INDEX', ['SUCCESS', 'FAIL'])
export const USERS_DELETE = users.defineAction('DELETE', ['SUCCESS', 'FAIL'])
export const USERS_UNDELETE = users.defineAction('UNDELETE', ['SUCCESS', 'FAIL'])
export const USERS_EDIT = users.defineAction('EDIT', ['SUCCESS', 'FAIL'])
export const USERS_CREATE = users.defineAction('CREATE', ['SUCCESS', 'FAIL'])
export const USERS_ACTIVITY = users.defineAction('ACTIVITY', ['SUCCESS', 'FAIL'])
export const USERS_CLEAR_ACTIVITY = users.defineAction('CLEAR_ACTIVITY')
export const USERS_DIVISIONS = users.defineAction('DIVISIONS}', ['SUCCESS', 'FAIL'])
export const USERS_FLUSH_MESSAGES = users.defineAction('FLUSH_MESSAGES')
export const USERS_ACTIVITY_REPORT = users.defineAction('ACTIVITY_REPORT', ['SUCCESS', 'FAIL'])

const templates = defineAction('TEMPLATES')
export const TEMPLATES_FETCH = templates.defineAction('FETCH', ['SUCCESS', 'FAIL'])
export const TEMPLATES_UPDATE = templates.defineAction('UPDATE', ['SUCCESS', 'FAIL'])
export const TEMPLATES_FLUSH_MESSAGES = templates.defineAction('FLUSH_MESSAGES')

const dashboard = defineAction('DASHBOARD')
export const DASHBOARD_RECENT_USERS = dashboard.defineAction('RECENT_USERS', ['SUCCESS', 'FAIL'])
export const DASHBOARD_LOGS = dashboard.defineAction('LOGS', ['SUCCESS', 'FAIL'])
export const DASHBOARD_DB_STATS = dashboard.defineAction('DB_STATS', ['SUCCESS', 'FAIL'])
