import * as actionTypes from '../actions/actionTypes'

export const fetchIndex = () => (dispatch) => {
  var request_url = `/ieps`

  dispatch({
    type: actionTypes.IEP_FETCH_INDEX,
    payload: {
      request: {
        url: request_url
      }
    }
  })
}

export const fetchIep = (id) => (dispatch) => {
  var request_url = `/ieps/${id}`

  dispatch({
    type: actionTypes.IEP_FETCH,
    payload: {
      request: {
        url: request_url
      }
    }
  })
}

export const updateValue = (path, value) => {
  return {
    type: actionTypes.IEP_UPDATE_VALUE,
    path: path,
    value: value,
  }
}

export const discardChanges = () => {
  return {
    type: actionTypes.IEP_DISCARD_CHANGES,
  }
}

/* Saves accumulated changes (iep.delta) to backend */
export const saveIep = (id) => (dispatch, getState) => {
  var request_url = `/author/ieps/${id}`
  var delta = getState().iep.delta

  dispatch({
    type: actionTypes.IEP_SAVE,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: delta
      }
    }
  })
}

/* Directly updates backend with changes */
export const updateIep = (id, key, value) => (dispatch) => {
  var request_url = `/author/ieps/${id}`
  var iep = {}
  iep[key] = value
  
  dispatch({
    type: actionTypes.IEP_UPDATE,
    key: key,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { iep: iep }
      }
    }
  })
}

export const fetchUserData = () => (dispatch) => {
  var request_url = `/author/user/data`

  dispatch({
    type: actionTypes.IEP_USER_DATA,
    payload: {
      request: {
        url: request_url
      }
    }
  })
}

export const fetchComments = (id) => (dispatch) => {
  var request_url = `/ieps/${id}/comments`

  dispatch({
    type: actionTypes.IEP_FETCH_COMMENTS,
    payload: {
      request: {
        url: request_url
      }
    }
  })
}

export const createComment = (id, content) => (dispatch) => {
  var request_url = `/comments`

  dispatch({
    type: actionTypes.IEP_CREATE_COMMENT,
    payload: {
      request: {
        url: request_url,
        method: 'post',
        data: { iep_id: id, content: content }
      }
    }
  })
}

export const deleteComment = (id) => (dispatch) => {
  var request_url = `/comments/${id}`

  dispatch({
    type: actionTypes.IEP_DELETE_COMMENT,
    comment_id: id,
    payload: {
      request: {
        url: request_url,
        method: 'delete',
      }
    }
  })
}

export const updateServices = (services) => (dispatch) => {
  var request_url = `/v1/user/services`

  dispatch({
    type: actionTypes.IEP_UPDATE_SERVICES,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { services: services }
      }
    }
  })
}

export const updateAssessmentTemplates = (templates) => (dispatch) => {
  var request_url = `/v1/user/assessment_templates`

  dispatch({
    type: actionTypes.IEP_UPDATE_ASSESSMENT_TEMPLATES,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { assessment_templates: templates }
      }
    }
  })
}

export const uploadAvatar = (id, file) => (dispatch) => {
  var request_url = `/author/ieps/${id}`

  var formData = new FormData()
  formData.append('photo', file)

  dispatch({
    type: actionTypes.IEP_UPLOAD_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: formData
      }
    }
  })
}

export const uploadThumbnail = (id, file) => (dispatch) => {
  var request_url = `/author/ieps/${id}`

  var formData = new FormData()
  formData.append('thumbnail', file)

  dispatch({
    type: actionTypes.IEP_UPLOAD_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: formData
      }
    }
  })
}

export const deleteAvatar = (id) => (dispatch) => {
  var request_url = `/author/ieps/${id}`
  
  dispatch({
    type: actionTypes.IEP_DELETE_AVATAR,
    payload: {
      request: {
        url: request_url,
        method: 'patch',
        data: { _delete_avatar: true }  
      }
    }
  })
}

export const fetchDocuments = (id) => (dispatch) => {
  var request_url = `/author/ieps/${id}/documents`

  dispatch({
    type: actionTypes.IEP_FETCH_DOCUMENTS,
    payload: {
      request: {
        url: request_url
      }
    }
  })
}

export const uploadDocument = (id, file) => (dispatch) => {
  var request_url = `/author/ieps/${id}/documents`

  var formData = new FormData()
  formData.append('document', file)

  dispatch({
    type: actionTypes.IEP_UPLOAD_DOCUMENT,
    payload: {
      request: {
        url: request_url,
        method: 'post',
        data: formData
      }
    }
  })
}

export const deleteDocument = (doc_id) => (dispatch) => {
  var request_url = `/author/documents/${doc_id}`

  dispatch({
    type: actionTypes.IEP_DELETE_DOCUMENT,
    payload: {
      request: {
        url: request_url,
        method: 'delete',
      }
    }
  })
}

export const stashDocument = (id) => (dispatch) => {
  var request_url = `/author/ieps/${id}/stash`

  dispatch({
    type: actionTypes.IEP_STASH_DOCUMENT,
    payload: {
      request: {
        url: request_url,
        method: 'post',
      }
    }
  })
}