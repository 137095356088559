import { combineReducers } from 'redux'
import auth from './authReducers'
import iep from './iepReducers'
import prefs from './prefsReducers'
import user from './userReducers'
import users from './usersReducers'
import templates from './templatesReducers'
import dashboard from './dashboardReducers'

export default combineReducers({
  auth: auth,
  iep: iep,
  user: user,
  prefs: prefs,
  users: users,
  templates: templates,
  dashboard: dashboard
})