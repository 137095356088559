import React, { useState } from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Switch, TextField, FormControlLabel, Tooltip, Typography, IconButton, Box, InputAdornment } from '@mui/material'
import InfoIcon from '@mui/icons-material/Help'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { grey } from '@mui/material/colors';
import { updateValue } from '../actions/iepActions'
import { useDispatch } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'

const cardStyles = makeStyles( (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    position: "relative"
  },
  cardHeader: {
    backgroundColor: grey[100],
    borderBottom: '1px solid #eee',
    padding: theme.spacing(1, 2)
  },
  title: {
    display: 'flex'
  },
  titleText: {
    flexGrow: 1
  },
  titleSort: {

  },
  infoIcon: {
    color: grey[500],
  }

}))

function CardIepSection(props) {
  const { children, title, editableTitlePath, editableTitle, deletable, onDelete, noPadding, handleAttributes, handleListeners } = props
  const [editing, setEditing] = useState(!(title?.length > 0))
  const [hover, setHover] = useState(false)
  const dispatch = useDispatch()
  const confirm = useConfirm()

  let classes = cardStyles()

  let content = noPadding ? children : <CardContent>{children}</CardContent>

  function handleTitleChange(e) {
    dispatch(updateValue(editableTitlePath, e.target.value))
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      setEditing(false)
    }
  }

  function handleDelete() {
    confirm({ title: "Are you sure?", description: "Are you sure you want to delete this section?  This action cannot be undone." })
    .then( () => {
      onDelete()
    })
  }

  function titleContent() {
    return (
      <Box sx={{ display: "flex" }}>
        { (editing && editableTitle) ? 
          <TextField 
            size="small" 
            value={title} 
            fullWidth
            label="Category Title" 
            onChange={handleTitleChange} 
            onKeyDown={handleKeyDown}
            autoFocus
            InputProps={{
              endAdornment: <InputAdornment position="end">
                              <IconButton
                                onClick={() => setEditing(false)}
                                edge="end"
                                size="small"
                                color="primary"
                              >
                                <DoneIcon />
                              </IconButton>
                            </InputAdornment>
            }}
          />
        :
          <Typography 
            component="div" 
            variant="h5" 
            sx={{mt: 1, mb: 0.75 }}
            color={(title?.length > 0) ? "inherit" : "lightgray"}
            onClick={() => setEditing(true)}
          >
            {title || "Untitled"}
          </Typography>
        }
        <Box sx={{flexGrow: 1}} />
        { !editing && hover && 
          <div>
            { editableTitle &&
              <IconButton sx={{ mt: 0.25 }} size="small" onClick={() => setEditing(true)}><EditIcon /></IconButton>          
            }
            { deletable &&
              <IconButton sx={{ mt: 0.25 }} size="small" onClick={handleDelete}><DeleteIcon /></IconButton>          
            }
          </div>
        }
        { props.sortable && 
          <div className={classes.titleSort}>
            <FormControlLabel 
              control={
                <Switch name="sorting" size="small" checked={props.sorting} onChange={ () => props.toggleSort() } />
              } 
              label="Sort" 
              labelPlacement="start"
              className={classes.sortSwitch}
            />
          </div>
        }
        { props.infoBlurb &&
          <Tooltip title={props.infoBlurb}>
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        }
      </Box>
    )
  }

  return (
      <Card className={classes.root}>
        <CardHeader 
          title={titleContent()} 
          className={classes.cardHeader} 
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        />
        {content}
      </Card>    
  )
}

export default React.memo(CardIepSection)