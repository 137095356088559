import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import LoadingDisplay from '../common/LoadingDisplay'
import ErrorDisplay from '../common/ErrorDisplay'
import { DeleteTwoTone } from '@mui/icons-material'
import { show, uploadAvatar, deleteAvatar } from '../actions/userActions'
import { Box } from '@mui/system';
import { Stack, CardMedia, Tooltip, IconButton } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from './AvatarEditor'

function Avatar(props) {
  const [status, setStatus] = useState("not_started")
  const [hover, setHover] = useState(false)
  const [intervalId, setIntervalId] = useState(null)
  const tokens = useSelector(state => state.auth.tokens)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  
  const onDrop = useCallback(acceptedFiles => {
    dispatch(uploadAvatar(acceptedFiles[0]))
  }, [])

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpg', '.jpeg']
    }
  })

  function fetchStatus() {
    axios.get(`/v1/jobs/refresh_user_avatar_status`, {
      headers: {
        'Authorization': `Bearer ${tokens.access_token}`
      }
    }).then((response) => {
      if (response.data.status === "completed") {
        clearInterval(intervalId)
        setStatus(response.data.status)
        setIntervalId(null)

        this.setState({ status: response.data.status, intervalId: null }, () => {
          dispatch(show())
        })
      } else {
        setStatus(response.data.status)
      }
    }).catch( () => {
      clearInterval(intervalId)
      setStatus("error")
      setIntervalId(null)
    })
  }

  function handleRefresh() {
    axios.post(`/v1/user/refresh_avatar`, {}, {
      headers: {
        'Authorization': `Bearer ${tokens.access_token}`
      }
    }).then(() => {
      var intId = setInterval(fetchStatus, 1500)
      setStatus("working")
      setIntervalId(intId)
    })
  }

  function handleAvatarDelete() {
    dispatch(deleteAvatar())
  }

  if (status === "working") {
    return <LoadingDisplay />
  } else if (status === "failed") {
    return <ErrorDisplay />
  }

  return (
    <React.Fragment>
      <CardMedia
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          { user.avatar ? 
            <Box
              component="img" 
              src={`data:image/jpeg;base64,${user.avatar}`} 
              alt="User avatar" 
              sx={{ width: "100%", height: "100%", marginBottom: -1 }}
            />
            :
            <Box 
              component={Stack}
              direction="column"
              justifyContent="center"
              sx={{
                width: "100%",
                minHeight: "150px",
                color: "#aaa",
                textAlign: "center",
                p: 2
              }}
            >
              Drag and drop, or click to upload a profile picture
            </Box>
          } 
        </div>
        { hover && user.avatar &&
          <Box 
            component={Stack} 
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ 
              position: "absolute", 
              bottom: "0px", 
              height: "48px", 
              width: "100%",
              backgroundColor: "black",
              opacity: 0.4,
            }}
          >
            <AvatarEditor />
            <Tooltip title="Delete">
                <IconButton 
                  onClick={handleAvatarDelete} 
                  size="small"
                >
                  <DeleteTwoTone sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
          </Box> 
        }      
      </CardMedia>
    </React.Fragment>
  )
}

export default Avatar