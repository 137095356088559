import React, { useState } from 'react'
import _ from 'lodash'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import * as actionTypes from '../actions/actionTypes'

export default function ServicesEditor(props) {
  let [services, setServices] = useState(_.clone(props.services))
  const dispatch = useDispatch()


  function handleChange(e) {
    let newServices = _.clone(services)
    newServices[e.target.name] = e.target.value
    if (e.target.value === "") {
      newServices = _.compact(newServices)
    }
    setServices(newServices)
  }

  function handleKeyUp(e) {
    if (e.key === 'Enter') {
      addItem()
    } else if ((e.key === 'Backspace') && (e.target.value === "")) {
      setServices(_.compact(services))
    }
  }

  function addItem() {
    setServices([...services, ""])
  }

  function saveChanges() {
    dispatch({
      type: actionTypes.USER_UPDATE,
      payload: {
        request: {
          url: "/user",
          method: 'patch',
          data: { services: services }
        }
      }    
    }).then(() => {
      props.onSave()
    }).catch(() => {
      props.onSave()
    })
  }

  let serviceFields = services.map((service, idx) => {
    return <TextField 
      key={idx}
      name={idx}
      label=""
      variant="filled"
      fullWidth
      margin="dense"
      value={service}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      hiddenLabel
      autoFocus
    />
  })

  return (
    <div>
      {serviceFields}
      <Box display="flex" mt={2} mb={1}>
        <Box flexGrow={1}>
          <Button color="primary" onClick={addItem}>Add Service</Button>
        </Box>
        <Button color="primary" variant="contained" onClick={saveChanges}>Save Changes</Button>
      </Box>
    </div>
  )
}