import React, { useState, useEffect, useRef } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { client } from './AxiosClient'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Divider from '@mui/material/Divider'
import { useSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import logo from './logo_sm.svg'
import { useMsal } from "@azure/msal-react"
import MicrosoftLoginButton from "./MicrosoftLoginButton"
import { loginRequest } from "./msalConfig"
import jwt_decode from 'jwt-decode'
import { loginError, receiveLogin, requestLogin } from './actions/authActions'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'


const StyledMicrosoftLoginButton = styled(MicrosoftLoginButton)(({ theme }) => ({
  fontSize: "15px !important"
}))

function LoginForm(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isFetchingAuth, setIsFetchingAuth] = useState(false)
  const { instance } = useMsal()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const theme = useTheme()
  const smallOrUp = useMediaQuery(theme.breakpoints.up('sm'))
  const googleButtonRef = useRef()

  useEffect(() => {
    instance.handleRedirectPromise().then((tokenResponse) => {
      if (_.get(tokenResponse, "account.username") && auth.isAuthenticating) {
        setIsFetchingAuth(true)
        client.post(`/login`, { email: tokenResponse.account.username, microsoft_access_token: tokenResponse.accessToken })
        .then((response) => {
          dispatch(receiveLogin(response.data))
        }).catch(error => {
          enqueueSnackbar(`${_.get(error.response, ["data", "error"])}`, { variant: "error" })
          dispatch(loginError(error.response))
          setIsFetchingAuth(false)
        })
      }
    }).catch((error) => {
      console.error(error)
    })

    console.log("Loading GSI script")
    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.onload = initializeGsi
    script.async = true
    script.id = "google-client-script"
    document.querySelector("body").appendChild(script)
    console.log("Loaded")
  }, [])

  function onGoogleClickHandler(){
    console.log("Sign in with Google button clicked...")
  }

  function initializeGsi() {
    console.log("Initializing GSI", window.google)
    window.google.accounts.id.initialize({
      log_level: 'debug',  // THE ALL-IMPORTANT SETTING
      client_id: "568730895009-qfjj0m826r9ubmrhbrq84h19c9m3h63e.apps.googleusercontent.com",
      callback: handleGoogleCallbackResponse
    })
    console.log("Initialized GSI, rendering", window.google)

    window.google.accounts.id.renderButton(
      googleButtonRef.current,
      { type: "standard", 
        theme: "outline", 
        size: "large", 
        text: "signin_with", 
        shape: "rectangular", 
        logo_alignment: "left", 
        width: "328", 
        locale: "en_CA",
        click_listener: onGoogleClickHandler
      }
    )
    console.log("Button rendered")

  }

  function handleGoogleCallbackResponse(response) {
    var userObj = jwt_decode(response.credential)
    setIsFetchingAuth(true)
    client.post(`/login`, { email: userObj.email, google_access_token: response.credential })
    .then(response => {
      dispatch(receiveLogin(response.data))
    }).catch(error => {
      enqueueSnackbar(`${_.get(error.response, ["data", "error"])}`, { variant: "error" })
      dispatch(loginError(error.response))
      setIsFetchingAuth(false)
    })
  }

  function handleLoginClick(e) {
    setIsFetchingAuth(true)
    dispatch(requestLogin())
    client.post(`/login`, { email: email, password: password })
    .then(response => {
      dispatch(receiveLogin(response.data))
    }).catch(error => {
      enqueueSnackbar(`${_.get(error.response, ["data", "error"])}`, { variant: "error" })
      dispatch(loginError(error.response))
      setIsFetchingAuth(false)
    })
  }

  function handleChangeEmail(event) {
    setEmail(event.target.value)
  }

  function handleChangePassword(event) {
    setPassword(event.target.value)
  }

  function handleMicrosoftLogin() {
    dispatch(requestLogin())
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
        enqueueSnackbar(`${_.get(e.response, ["data", "error"])}`, { variant: "error" })
        dispatch(loginError(e.response))
    })
  }


  if (auth.tokens.access_token) {
    const loc = (location.state || {}).from || "/"
    return <Navigate to={loc} />
  }

  return (
    <Box 
      height='calc(100vh)'
      sx={{
        width: {
          xs: "100%",
          sm: 400
        },
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-48px'
      }}
    >
      <Box sx={{ flexGrow: 1 }} />
      <Paper sx={{ textAlign: 'center', pt: 2, px: 3, pb: 3 }} elevation={smallOrUp ? 3 : 0}>
        <Box 
          component="img" 
          alt="IEP Centre" 
          src={logo} 
          sx={{ 
            mx: 2,
            my: 4,
            maxWidth: '320px',
            width: '100%',
            height: '70px'
          }}
        />
        <form id="login-form">
          <TextField 
            margin="normal" 
            autoComplete="username" 
            disabled={isFetchingAuth} 
            required 
            fullWidth 
            id="email" 
            name="email" 
            label="Email Address" 
            value={email} 
            onChange={handleChangeEmail} />
          <TextField 
            margin="normal" 
            autoComplete="current-password" 
            disabled={isFetchingAuth} 
            type="password" 
            required 
            fullWidth 
            id="password" 
            name="password" 
            label="Password" 
            value={password} 
            onChange={handleChangePassword} />
          <Button 
            type="submit" 
            disabled={isFetchingAuth} 
            onClick={handleLoginClick} 
            fullWidth 
            variant="contained" 
            color="primary" 
            id="login-submit"
            sx={{ mt: 2 }}>
            Sign in
          </Button>
          <Box sx={{textAlign: 'right', pt: 1, pb: 2}}>
            <Link
              component={RouterLink}
              to="/forgot_password"
              underline="hover">Forgot your password?</Link>
          </Box>
        </form>

        <Divider variant="middle" sx={{ my: 2, mx: 0 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box 
            id="googleSignInButton" 
            ref={googleButtonRef}
            sx={{ width: '328px', mt: 4 }}
          />
          <Box sx={{ mt: 3, width: '338px' }}>
            <StyledMicrosoftLoginButton 
              onClick={() => handleMicrosoftLogin()} 
            />
          </Box>
        </Box>
      </Paper>
      <Box sx={{flexGrow: 2}} />
    </Box>
  )
}

export default LoginForm