import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import { client } from '../AxiosClient'
import Box from '@mui/material/Box'

export default function LoginForm() {
  // const classes = useStyles()
  const [email, setEmail] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSend = () => {
    client.post(`/forgot_password`, { email: email })
      .then(() => {
        navigate("/")
        enqueueSnackbar("Password reset email sent.", { variant: "success" })
      }).catch(error => {
        enqueueSnackbar("Could not initiate password reset.  Please try again later.", { variant: "error" })
      })
  }

  return (
    <main>
      <Box sx={{
        width: {
          xs: "100%",
          sm: 400
        },
        mx: 'auto',
        marginTop: "72px"
      }}>
        <Paper sx={{ pt: 2, px: 3, pb: 3 }}>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Reset Password
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            Please provide the email address associated with your IEP Centre account.  We will send you an email that will allow you to reset your password.
          </Typography>
          <TextField sx={{ my: 2 }} variant="outlined" label="Email address" name="email" type="email" fullWidth size="small" value={email} onChange={handleChange} />
          <Button variant="contained" color="primary" fullWidth onClick={handleSend}>Send verification email</Button>
        </Paper>
      </Box>
    </main>
  )
}