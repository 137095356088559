import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const withMediaQuery = Component => props => {
  const theme = useTheme()
  const mediaQuery = {
    small: useMediaQuery(theme.breakpoints.up('sm')),
    medium: useMediaQuery(theme.breakpoints.up('md')),
    large: useMediaQuery(theme.breakpoints.up('lg'))
  }

  return <Component mediaQuery={mediaQuery} {...props} />
}

export default withMediaQuery