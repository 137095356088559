import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash'
// import { setIn } from 'lodash-redux-immutability'

function valuesAreEqual(value1, value2) {
  if (_.isString(value1)) {
    return ((value1 || "") === (value2 || ""))
  } else if (_.isNumber(value1)) {
    return _.isEqual(_.toString(value1), _.toString(value2))
  } else if (_.isObject(value1)) {
    return _.isEqual(value1, value2)
  } else if (_.isNil(value1)) {
    return (value2 || "") === ""
  }

  return value1 === value2
}

const Iep = (state = {
  ieps: [],
  ieps_status: "not_loaded",         // "not_loaded" -> "loading" -> "loaded" || "error"
  ieps_retries: 0,
  iep: {},
  delta: {},
  saving_delta: {},
  save_status: "idle", // "idle" -> "saving" -> "error"
  iep_status: "not_loaded",
  iep_retries: 0,
  updating: [],
  errors: [],
  data: {},
  data_status: "not_loaded",
  goal_updating: [],
  goal_errors: [],
  comments: [],
  comments_status: "not_loaded",
  comments_retries: 0,
  messages: [],
  documents: [],
  documents_status: "not_loaded",
  documents_retries: 0,
}, action) => {
  switch(action.type) {
  case actionTypes.IEP_FETCH_INDEX:
    return { ...state, 
      ieps: [],
      ieps_status: "loading",
    }
  case actionTypes.IEP_FETCH_INDEX.SUCCESS:
    return { ...state,
      ieps: _.get(action.payload, "data", {}),
      ieps_status: "loaded",
      ieps_retries: 0
    }
  case actionTypes.IEP_FETCH_INDEX.FAIL:
    return { ...state,
      ieps: [],
      ieps_status: "error",
      ieps_retries: state.ieps_retries + 1   
    }
  case actionTypes.IEP_FETCH:
    return { ...state, 
      iep: {},
      iep_status: "loading",
    }
  case actionTypes.IEP_FETCH.SUCCESS:
    return { ...state,
      iep: _.get(action.payload, "data", {}),
      delta: {},
      iep_status: "loaded",
      iep_retries: 0
    }
  case actionTypes.IEP_FETCH.FAIL:
    return { ...state,
      iep: {},
      iep_status: "error",
      iep_retries: state.ieps_retries + 1   
    }
  case actionTypes.IEP_SAVE:
    return { ...state,
      saving_delta: state.delta,
      delta: {},
      save_status: "saving",
    }
  case actionTypes.IEP_SAVE.SUCCESS:
    return { ...state,
      iep: _.get(action.payload, "data", {}),
      saving_delta: {},
      save_status: "idle",
      messages: [...state.messages,
        { message: "IEP updated successfully", type: "success" }
      ]     
    }
  case actionTypes.IEP_SAVE.FAIL:
    return { ...state,
      save_status: "idle",
      delta: state.saving_delta,
      saving_delta: {},
      messages: [...state.messages,
        { message: "IEP could not be saved - please try again.", type: "error" }
      ]     
    }
  case actionTypes.IEP_UPDATE:
    return { ...state, 
      updating: [ ...state.updating, action.key ],
      errors: _.without(state.errors, action.key),
      iep_status: "updating",
    }
  case actionTypes.IEP_UPDATE.SUCCESS:
    return { ...state,
      updating: _.without(state.updating, action.meta.previousAction.key),
      iep: _.get(action.payload, "data", {}),
      iep_status: "loaded",
      iep_retries: 0
    }
  case actionTypes.IEP_UPDATE.FAIL:
    return { ...state,
      errors: [ ...state.errors, action.meta.previousAction.key ],
      iep_status: "error",
      iep_retries: state.ieps_retries + 1   
    }
  case actionTypes.IEP_UPDATE_VALUE: {
    const path = _.toPath(action.path)
    const basePath = _.toPath(path[0])
    
    let newDelta = _.cloneDeep(state.delta)

    if (_.has(state.delta, path)) {
      // console.log(`IEP - Delta 1 "${JSON.stringify(_.get(state.iep, path))}" "${JSON.stringify(_.get(state.delta, path))}" "${action.value}" path: ${path}`)
      if (valuesAreEqual(_.get(state.iep, path), action.value)) {
        if (path.length === 1) { // unset simple value
          _.unset(newDelta, path)
        } else {
          _.set(newDelta, path, action.value)
          let iepBase = _.get(state.iep, basePath)
          let deltaBase = _.get(newDelta, basePath)
          if (_.isEqual(iepBase, deltaBase)) {
            _.unset(newDelta, basePath)
          }
        }

        return {
          ...state,
          delta: newDelta
        }
      } else { // values are not equal
        _.set(newDelta, path, action.value)
        return {
          ...state,
          delta: newDelta
        }
        // return setIn(state, ["delta", ...path], action.value)
      }
    }

    // simple path DOESN'T exist in delta
    if (path.length === 1) {
      _.set(newDelta, path, action.value)
      return {
        ...state,
        delta: newDelta
      }
    }

    // for complex paths...
    // if basepath doesn't exist, clone it from the IEP
    if (!_.has(newDelta, basePath)) {
      _.set(newDelta, basePath, _.cloneDeep(_.get(state.iep, basePath)))
    }
    // and set the new value
    _.set(newDelta, path, action.value)

    // console.log(`IEP - Delta 2 "${JSON.stringify(_.get(state.iep, basePath))}" "${JSON.stringify(_.get(newDelta, basePath))}"`)
    let iepBase = _.get(state.iep, basePath)
    let deltaBase = _.get(newDelta, basePath)

    if (_.isEqual(iepBase, deltaBase)) {
      _.unset(newDelta, basePath)
    }

    return {
      ...state,
      delta: newDelta
    }
  }
  case actionTypes.IEP_DISCARD_CHANGES: {
    return { ...state,
      delta: {},
    }
  }
  case actionTypes.IEP_USER_DATA:
    return { ...state, 
      data: {},
      data_status: "loading"
    }
  case actionTypes.IEP_USER_DATA.SUCCESS:
    return { ...state,
      data: _.get(action.payload, "data", {}),
      data_status: "loaded"
    }
  case actionTypes.IEP_USER_DATA.FAIL:
    return { ...state,
      data: {},
      data_status: "error"
    }
  case actionTypes.IEP_FETCH_COMMENTS:
    return {
      ...state,
      comments: [],
      comments_status: "loading"
    }
  case actionTypes.IEP_FETCH_COMMENTS.SUCCESS:
    return {
      ...state,
      comments: _.get(action.payload, "data", []),
      comments_status: "loaded",
      comments_retries: 0
    }
  case actionTypes.IEP_FETCH_COMMENTS.FAIL:
    return {
      ...state,
      comments: [],
      comments_status: "error",
      comments_retries: state.comments_retries + 1
    }
  case actionTypes.IEP_CREATE_COMMENT:
    return {
      ...state,
      comments_status: "updating"
    }
  case actionTypes.IEP_CREATE_COMMENT.SUCCESS:
    return {
      ...state,
      comments: [_.get(action.payload, "data"), ...state.comments],
      comments_status: "loaded",
      comments_retries: 0
    }
  case actionTypes.IEP_CREATE_COMMENT.FAIL:
    return {
      ...state,
      comments_status: "error",
      comments_retries: state.comments_retries + 1
    }
  case actionTypes.IEP_DELETE_COMMENT:
    return {
      ...state,
      comments_status: "updating"
    }
  case actionTypes.IEP_DELETE_COMMENT.SUCCESS: {
    let newComments = _.clone(state.comments)
    _.remove(newComments, (c) => { return c.id === action.meta.previousAction.comment_id })
    return { 
      ...state,
      comments: newComments,
      comments_status: "loaded",
      comments_retries: 0
    }
  }
  case actionTypes.IEP_DELETE_COMMENT.FAIL:
    return {
      ...state,
      comments_status: "error",
      comments_retries: state.comments_retries + 1
    }
  case actionTypes.IEP_REORDER_PERFORMANCE_TEST:
    return { ...state, 
      updating: [ ...state.updating, "performance_tests" ],
      errors: _.without(state.errors, "performance_tests"),
      iep_status: "updating",
    }
  case actionTypes.IEP_REORDER_PERFORMANCE_TEST.SUCCESS:
    return { ...state,
      updating: _.without(state.updating, "performance_tests"),
      iep: { 
        ...state.iep,
        performance_tests: action.payload.data,
      },
      iep_status: "loaded",
      iep_retries: 0
    }
  case actionTypes.IEP_REORDER_PERFORMANCE_TEST.FAIL:
    return { ...state,
      errors: [ ...state.errors, "performance_tests" ],
      iep_status: "error",
      iep_retries: state.ieps_retries + 1   
    }
  case actionTypes.IEP_UPDATE_SERVICES:
    return { ...state, 
      data: {...state.data, services: []},
      data_status: "loading"
    }
  case actionTypes.IEP_UPDATE_SERVICES.SUCCESS:
    return { ...state,
      data: {...state.data, services: action.payload.data},
      data_status: "loaded"
    }
  case actionTypes.IEP_UPDATE_SERVICES.FAIL:
    return { ...state,
      data: {...state.data, services: []},
      data_status: "error"
    }
  case actionTypes.IEP_UPDATE_ASSESSMENT_TEMPLATES:
    return { ...state, 
      data: {...state.data, assessment_templates: []},
      data_status: "loading"
    }
  case actionTypes.IEP_UPDATE_ASSESSMENT_TEMPLATES.SUCCESS:
    return { ...state,
      data: {...state.data, assessment_templates: action.payload.data},
      data_status: "loaded"
    }
  case actionTypes.IEP_UPDATE_ASSESSMENT_TEMPLATES.FAIL:
    return { ...state,
      data: {...state.data, assessment_templates: []},
      data_status: "error"
    }
  case actionTypes.IEP_UPLOAD_AVATAR:
  case actionTypes.IEP_DELETE_AVATAR:
    return { ...state, 
      updating: [ ...state.updating, 'avatar' ],
      errors: _.without(state.errors, 'avatar'),
      iep_status: "updating",
      save_status: "saving"
    }
  case actionTypes.IEP_UPLOAD_AVATAR.SUCCESS:
  case actionTypes.IEP_DELETE_AVATAR.SUCCESS:
    return { ...state,
      updating: _.without(state.updating, 'avatar'),
      iep: action.payload.data,
      iep_status: "loaded",
      iep_retries: 0,
      save_status: "idle"
    }
  case actionTypes.IEP_UPLOAD_AVATAR.FAIL:
  case actionTypes.IEP_DELETE_AVATAR.FAIL:
    return { ...state,
      errors: [ ...state.errors, 'avatar' ],
      iep_status: "error",
      iep_retries: state.ieps_retries + 1,
      save_status: "idle"
    }
  case actionTypes.IEP_FETCH_DOCUMENTS:
    return {
      ...state,
      documents: [],
      documents_status: "loading"
    }
  case actionTypes.IEP_FETCH_DOCUMENTS.SUCCESS:
    return {
      ...state,
      documents: _.get(action.payload, "data", []),
      documents_status: "loaded",
      documents_retries: 0
    }
  case actionTypes.IEP_FETCH_DOCUMENTS.FAIL:
    return {
      ...state,
      documents: [],
      documents_status: "error",
      documents_retries: state.documents_retries + 1
    }
  case actionTypes.IEP_UPLOAD_DOCUMENT:
  case actionTypes.IEP_DELETE_DOCUMENT:
  case actionTypes.IEP_STASH_DOCUMENT:
    return { ...state, 
      documents_status: "updating",
    }
  case actionTypes.IEP_UPLOAD_DOCUMENT.SUCCESS:
  case actionTypes.IEP_STASH_DOCUMENT.SUCCESS:
    return { ...state,
      documents: [...state.documents, action.payload.data],
      documents_status: "loaded",
      documents_retries: 0,
      messages: [...state.messages,
        { message: "Document uploaded successfully", type: "success" }
      ]
    }
  case actionTypes.IEP_DELETE_DOCUMENT.SUCCESS: {
    let documents = _.filter(state.documents, (doc) => { return doc.id !== action.payload.data.id })
    return { ...state,
      documents: documents,
      documents_status: "loaded",
      documents_retries: 0,
      messages: [...state.messages,
        { message: "Document deleted successfully", type: "success" }
      ]
    }
  }
  case actionTypes.IEP_STASH_DOCUMENT.FAIL:
  case actionTypes.IEP_UPLOAD_DOCUMENT.FAIL:
    return { ...state,
      documents_status: "error",
      documents_retries: state.ieps_retries + 1, 
      messages: [...state.messages,
        { message: "Document could not be uploaded - an error occurred.", type: "error" }
      ]
    }
  case actionTypes.IEP_DELETE_DOCUMENT.FAIL:
    return { ...state,
      documents_status: "error",
      documents_retries: state.ieps_retries + 1, 
      messages: [...state.messages,
        { message: "Document could not be deleted - an error occurred.", type: "error" }
      ]
    }
  case actionTypes.IEP_FLUSH_MESSAGES:
    return { ...state,
      messages: []
    }
  default:
    return state
  }
}

export default Iep