import * as actionTypes from '../actions/actionTypes'

export const setTab = (iep_id, tab) => {
  return {
    type: actionTypes.PREFS_SET_TAB,
    iep_id: iep_id,
    tab: tab
  }
}

export const setDrawerStatus = (open) => {
  return {
    type: actionTypes.PREFS_SET_DRAWER_STATUS,
    status: open
  }
}

export const setMobileDrawerStatus = (open) => {
  return {
    type: actionTypes.PREFS_SET_MOBILE_DRAWER_STATUS,
    status: open
  }  
}

export const setSearchString = (search) => {
  return {
    type: actionTypes.PREFS_SET_SEARCH_STRING,
    search: search
  }  
}

export const setScope = (scope) => {
  return {
    type: actionTypes.PREFS_SET_SCOPE,
    scope: scope
  }  
}
