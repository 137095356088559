import React from "react"
import { createButton, createSvgIcon } from 'react-social-login-buttons'

const config = {
  text: "Sign in with Microsoft",
  icon: createSvgIcon(Icon),
  iconSize: "22px",
  align: "center",
  style: { 
    background: "white", 
    color: "#5e5e5e", 
    height: "38px",
    fontFamily: "Segoe UI Regular, Roboto, Helvetica, sans-serif" 
  },
  activeStyle: { 
    background: "#fafafa" 
  }
}

const MicrosoftLoginButton = createButton(config);
export default MicrosoftLoginButton


function Icon({ width, height, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 129 129">
      <path d="M0,0h61.3v61.3H0V0z" fill="#F25022" />
      <path d="M67.7,0H129v61.3H67.7V0z" fill="#7FBA00" />
      <path d="M0,67.7h61.3V129H0V67.7z" fill="#00A4EF" />
      <path d="M67.7,67.7H129V129H67.7V67.7z" fill="#FFB900" />
    </svg>
  )
}