import * as actionTypes from '../actions/actionTypes'

const Prefs = (state = {
  iep_id: undefined,
  tab: undefined,
  showDrawer: true,
  mobileDrawer: false,
  searchString: "",
  scope: "All IEPs",
}, action) => {
  switch (action.type) {
  case actionTypes.PREFS_SET_TAB:
    return { ...state, 
      iep_id: action.iep_id,
      tab: action.tab,
    }
  case actionTypes.PREFS_SET_DRAWER_STATUS:
    return { ...state,
      showDrawer: action.status,
    }
  case actionTypes.PREFS_SET_MOBILE_DRAWER_STATUS:
    return { ...state,
      mobileDrawer: action.status,
    }
  case actionTypes.PREFS_SET_SEARCH_STRING:
    return { ...state, 
      searchString: action.search
    }
  case actionTypes.PREFS_SET_SCOPE:
    return { ...state,
      scope: action.scope
    }
  default:
    return state
  }
}

export default Prefs