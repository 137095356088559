import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash'

const Users = (state = {
  users: [],
  divisions: [],
  status: "not_loaded",
  activity: { month: {}, school_year: {}, all_ieps: [] },
  activity_report: [],
  messages: []
}, action) => {
  switch (action.type) {
  case actionTypes.USERS_INDEX:
    return { ...state,
      users: [],
      status: "loading",
    }
  case actionTypes.USERS_INDEX.SUCCESS:
    return { ...state,
      users: action.payload.data,
      status: "loaded"
    }
  case actionTypes.USERS_INDEX.FAIL:
    return { ...state,
      users: [],
      status: "error"
    }
  case actionTypes.USERS_DIVISIONS:
    return { ...state,
      divisions: [],
    }
  case actionTypes.USERS_DIVISIONS.SUCCESS:
    return { ...state,
      divisions: action.payload.data,
    }
  case actionTypes.USERS_DIVISIONS.FAIL:
    return { ...state,
      divisions: [],
    }
  // case actionTypes.USERS_DELETE.SUCCESS:
  //   return { ...state,
  //     users: state.users.filter(user => user.id !== action.meta.previousAction.user_id)
  //   }
  case actionTypes.USERS_DELETE.FAIL:
  case actionTypes.USERS_UNDELETE.FAIL:
  case actionTypes.USERS_EDIT.FAIL:
    return { ...state,
      status: "error",
      messages: [...state.messages,
       { message: "Could not edit user - an unknown error occurred.", type: "error" }
      ] 
    }
  case actionTypes.USERS_CREATE.FAIL:
    return { ...state,
      status: "error",
      messages: [...state.messages,
       { message: "Could not create user - an unknown error occurred.", type: "error" }
      ] 
    }
  case actionTypes.USERS_DELETE.SUCCESS: {
    let newState = _.cloneDeep(state)
    let idx = _.findIndex(newState.users, { 'id': action.meta.previousAction.user_id })
    if (idx > 0) {
      newState.users[idx] = action.payload.data
    }
    return { 
      ...newState
    }    
  }
  case actionTypes.USERS_UNDELETE.SUCCESS: {
    let newState = _.cloneDeep(state)
    let idx = _.findIndex(newState.users, { 'id': action.meta.previousAction.user_id })
    if (idx > 0) {
      newState.users[idx] = action.payload.data
    }
    return { 
      ...newState
    }
  }
  case actionTypes.USERS_EDIT.SUCCESS: {
    let newState = _.cloneDeep(state)
    let idx = _.findIndex(newState.users, { 'id': action.meta.previousAction.user_id })
    newState.users[idx] = action.payload.data
    return {
      ...newState,
      messages: [...state.messages,
       { message: "User account updated successfully", type: "success" }
      ]
    }    
  }
  case actionTypes.USERS_CREATE.SUCCESS: {
    let newState = _.cloneDeep(state)
    newState.users.push(action.payload.data)
    return {
      ...newState,
      messages: [...state.messages,
       { message: "User account created successfully", type: "success" }
      ]
    }    
  }
  case actionTypes.USERS_ACTIVITY.SUCCESS:
    return {
      ...state,
      activity: action.payload.data
    } 
  case actionTypes.USERS_CLEAR_ACTIVITY:
    return {
      ...state,
      activity: { month: {}, school_year: {}, all_ieps: [] }
    }  
  case actionTypes.USERS_FLUSH_MESSAGES:
    return { ...state,
      messages: []
    }
  case actionTypes.USERS_ACTIVITY_REPORT.SUCCESS:
    return {
      ...state,
      activity_report: action.payload.data
    } 
  default:
    return state
  }
}

export default Users